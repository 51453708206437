<template>
  <div class="page-wrap gov-model">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智能客服营销一体化解决方案</div>
          <div class="desc">全渠道客户触点统一接待，覆盖售前、售中、售后服务场景，沉淀服务过程的用户数据，以人工智能技术，提升企业客服工作效率，持续优化服务体验，让企业的服务真正发挥商业作用。让服务和营销变得更主动、更智能、更懂人心，在存量竞争时代实现商业价值和品牌价值的双重提升。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 行业痛点 -->
    <div class="section bg-white pain">
      <div class="title">汽车行业销售及服务领域现状</div>
      <div class="sub-title">针对呼入、呼出、质检等销服场景，亟需引入AI智能客服，助力车企降本增效</div>
      <div class="content">
        <div class="pain-list">
          <div
              class="item"
              v-for="(item, index) in painList"
              :key="index"
          >
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 整体解决方案 -->
    <div class="section solution">
      <div class="title">整体解决方案</div>
      <div class="sub-title">基于前台服务营销、后台运营管理 提供“全渠道、全触点”数字化服务转型解决方案</div>
      <div class="content">
        <div class="bg-sol-1"></div>
        <div class="desc">通过语音识别、语音合成、语义理解、虚拟形象等AI技术，在前台提供“智能外呼”、“智能语音客服”、“在线文字客服”、“数字人客服”等产品，实现业务营销及服务需求，在后台提供“语音分析”、“随销助手”、“坐席辅助”、“培训机器人”等产品，实现后台运营管理需求，解决当前“人工客服并发难、成本高、流失率高、监管难”等问题，且支持电话端、手机端、电脑端多渠道接入。</div>
      </div>
    </div>

    <!-- 接触-购买-使用”全链路赋能 -->
    <div class="section bg-white service">
      <div class="title">接触-购买-使用”全链路赋能</div>
      <div class="content">
        <div class="bg-service"></div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="section solution">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="bg-sol-2"></div>
      </div>
    </div>

    <!-- 平台功能特性 -->
    <div class="section bg-white adv">
      <div class="title">平台功能特性</div>
      <div class="content">
        <div class="adv-list">
          <div class="item" v-for="item in advList" :key="item.name">
            <div class="top">
              <div class="icon">
                <img :src="getIcon(item)"/>
              </div>
              <div class="title">{{item.name}}</div>
            </div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户案例 -->
<!--    <div class="section case">
      <div class="title">某龙头车企应用案例：意向用户筛选及邀约试驾</div>
      <div class="content">
        <div class="case-detail">
          <div class="d-item">
            <div class="title">建设背景</div>
            <div class="content">某龙头车厂每年花费大量成本进行广告宣传，针对广告商反馈的意向用户，由于人力紧张、验证时效高，无法实现短时间 意向用户筛选，确认广告投放价值及意向用户落单推动。</div>
          </div>
          <div class="d-item">
            <div class="title">建设成效</div>
            <div class="content">完成意向用户筛选及邀约试驾，单日外呼量高达上万通，及时验证广告效果、意向客探。</div>
            <div class="content vs">
              <div class="item">
                <img src="@/assets/icsm-is/bg-case-1.png" style="width: 477px;height: 314px" />
                <div class="text">传统模式</div>
              </div>
              <div class="item">
                <img src="@/assets/icsm-is/bg-case-2.jpg" style="width: 477px;height: 314px" />
                <div class="text">智能外呼</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      painList: [
        {
          name: '',
          icon: 'icon-pain-1.png',
          content: '每年大量人工咨询服务，人工成本高昂、人员流失率高、招聘及培训成本上涨、存量经营服务提升需求',
        },
        {
          name: '',
          icon: 'icon-pain-2.png',
          content: '存量经营，车企需加强服务，派单通知、首保提醒、意向客探调查问卷，车企人力不足，无法做到一对一外呼、回访',
        },
        {
          name: '',
          icon: 'icon-pain-3.png',
          content: '4S店报价混乱、销售服务无法监控；车企需每月对4S人员销售考核，有固定秘采成本，秘采率1%',
        }
      ],
      advList: [
        {
          name: '高识别率',
          icon: 'icon-adv-1.png',
          desc: '深度全序列卷积神经网络DFCNN最新版语音识别引擎，电话信道下识别效果领先市面一代。'
        },
        {
          name: '高自然度',
          icon: 'icon-adv-2.png',
          desc: 'XTTS新一代合成技术，可将文本合成为高品质语音，效果自然流畅，可与人声相媲美。'
        },
        {
          name: '智能打断',
          icon: 'icon-adv-3.png',
          desc: '支持在对话交互过程中在任意时刻说话，快速应答响应用户需求。'
        },
        {
          name: '全渠道覆盖',
          icon: 'icon-adv-4.png',
          desc: 'IVR菜单扁平化，用户自由说，支持 电话、小程序等全渠道覆盖'
        },
        {
          name: '客服平台中间件',
          icon: 'icon-adv-5.png',
          desc: '对已有电话平台和CRM的企业可以使用平台能力中间件，快速拥有机器人对话交互能力。'
        },
        {
          name: '人机协同',
          icon: 'icon-adv-6.png',
          desc: '在机器人无法服务客户需求时，人工及时辅助机器人或接管服务，保障客户一致的体验。'
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/icsm-is/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
